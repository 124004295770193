.container {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  padding: 130px 25px 30px;
  position: relative;
}

.container > span {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.introsWrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.successFinalDot {
  cursor: pointer;
  border-radius: 100vw;
  width: 12vw;
  height: 12vw;
  margin: 2rem auto;
  background-color: var(--green);
  color: #fff;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  mix-blend-mode: multiply;
}

.blueRibbon {
  width: 700px;
  height: 100px;
  background-color: var(--teams);
  border-radius: 0 26px 26px 0;
  position: absolute;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 50%;
  left: -3px;
  transform: translateY(-50%) translateX(-100%);
}

.blueRibbon-right {
  background-color: var(--standalone);
  justify-content: flex-start;
  border-radius: 26px 0 0 26px;
  left: auto;
  right: -3px;
  transform: translateY(-50%) translateX(100%);
}

.blueRibbon i {
  transform: scale(1.2);
  margin-bottom: 0.5rem;
}

.blueRibbon svg path {
  fill: #fff;
}

.blueRibbon-right,
.blueRibbon-right svg path {
  fill: #222;
  color: #222 !important;
}

.blueRibbon > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-inline: 2em;
  font-size: 15px;
  font-weight: bold;
}

.purposeCard {
  width: 100vw;
  position: absolute;
  bottom: 0;
  left: 0;
}

.purposeCard svg {
  width: 100%;
  height: auto;
}

.purposeCard svg,
.purposeCard svg *,
.purposeCard .non-interactive {
  pointer-events: none;
}

.purposeCard svg rect:not(:first-of-type) {
  pointer-events: all;
}

.purposeCard svg rect:hover,
.purposeCard svg rect:active {
  fill: rgba(0, 0, 0, 0.1);
}
