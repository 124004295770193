.wrapper {
  display: flex;
  position: absolute;
  z-index: 2;
  padding-top: 70px;
  padding-left: 25px;
  /* pointer-events: none; */
}

.closeBtn {
  padding: 10px;
  border-radius: 1000px;
  position: absolute;
  top: 5px;
  left: 0;
}

.step {
  pointer-events: none;
  cursor: pointer;
  border-radius: 100vw;
  width: 40px;
  height: 40px;
  margin: 0 10px 0 0;
  background-color: var(--gray-ultra-light);
  display: flex;
  align-items: center;
  justify-content: center;
  mix-blend-mode: multiply;
}

.step svg {
  width: 20px;
  height: 20px;
}

.step:hover,
.step:active,
.isActive {
  background-color: var(--primary);
  color: #fff;
}

.step > svg {
  position: absolute;
}

.step > svg path {
  fill: var(--gray-light);
}

.step.isDone:not(.isActive) {
  background-color: var(--indigo-extra-light);
  animation: success 1s linear;
}

.step.isDone > svg path {
  fill: var(--primary);
}

.step.isActive > svg path {
  fill: #fff;
}

.step > svg:first-child,
.step.isDone.isActive > svg:first-child,
.step:hover > svg:first-child {
  opacity: 0;
}

.step > svg:last-child,
.step.isDone.isActive > svg:last-child,
.step:hover > svg:last-child {
  opacity: 1;
}

.step.isActive > svg path,
.step:hover > svg path {
  fill: #fff;
}

.isDone > svg:first-child {
  opacity: 1;
}

.isDone > svg:last-child {
  opacity: 0;
}

@keyframes success {
  0% {
    fill: #fff;
    color: #fff;
    background-color: var(--green);
  }
  100% {
    color: var(--primary);
    fill: var(--primary);
    background-color: var(--indigo-extra-light);
  }
}
