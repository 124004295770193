* {
  -webkit-highlight: none;
  -webkit-highlight-color: transparent !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

:root {
  --indigo: #5173ee;
  --black: #201e1e;
  --indigo-extra-light: rgba(81, 115, 238, 0.05);
  --gray-ultra-light: rgba(0, 0, 0, 0.05);
  --gray-light: rgba(0, 0, 0, 0.2);
  --green: #32ae1e;
  --blue: #0091ea;
  --teams: #206dd0;
  --standalone: #ffa800;

  --primary: var(--indigo);
  --secondary: var(--blue);

  /* --animate-duration: 0.3s;
  --animate-delay: 0.9s; */
}

body {
  margin: 0;
  font-family: "SF Pro", "Inter", -apple-system, "Roboto", "Helvetica Neue",
    sans-srif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black);
}

#root,
html,
body {
  max-width: 100vw;
  max-height: 100vh;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  max-width: 100%;
}

h1 strong {
  color: var(--primary);
}

h1 {
  margin: 0;
}

h1.steptitle {
  font-size: 28px;
  line-height: 130%; /* 36.4px */
  letter-spacing: -0.56px;
  min-height: 128px;
}

h3 {
  font-size: 18px;
  font-weight: normal;
}

button {
  background: var(--primary);
  color: #fff;
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
  padding: 20px;
  border-radius: 1000px;
  border: 0;
  width: 100%;
}

footer {
  position: absolute;
  display: flex;
  bottom: 30px;
  left: 0;
  right: 0;
  margin-inline: 6vw;
  justify-content: center;
  align-items: center;
}

footer h4 {
  width: 100%;
  margin: 0;
}

footer button {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1000px;
  animation: attention ease-in-out 1s infinite alternate;
}

@keyframes attention {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
